import React from "react";

export const MacbookPro = () => {
  return (
    <div className="bg-[#ffffff] flex flex-row justify-center w-full">
      <div className="bg-[#ffffff] overflow-hidden w-[1400px] h-[4447.22px] relative">
        <div className="absolute w-[1345px] h-[606px] top-10 left-[31px]">
          <img
            className="absolute w-[201px] h-[33px] top-px left-0.5"
            alt="Rectangle"
            src="./src/screens/Resource/img/rectangle-24.svg"
          />

          <img
            className="absolute w-[1345px] h-[604px] top-px left-0"
            alt="Mask group"
            src="./src/screens/Resource/img/mask-group.png"
          />

          <img
            className="absolute w-[67px] h-6 top-[5px] left-2.5"
            alt="Mask group"
            src="./src/screens/Resource/img/mask-group-1@2x.png"
          />

          <img
            className="absolute w-[83px] h-[22px] top-1.5 left-[81px]"
            alt="Mask group"
            src="./src/screens/Resource/img/mask-group-2@2x.png"
          />

          <img
            className="absolute w-[720px] h-[605px] top-0 left-[625px]"
            alt="Rectangle"
            src="./src/screens/Resource/img/rectangle-11.svg"
          />

          <div className="flex flex-col w-[598px] items-start absolute top-[275px] left-[713px]">
            <p className="relative self-stretch mt-[-0.81px] font-medium text-[#fcda86] text-[23.3px] text-right leading-[35.0px] [font-family:'Urbanist',Helvetica] tracking-[0]">
              Forget the old ways,
              <br /> finding gigs never been easier
            </p>

            <div className="flex flex-col w-[600.34px] items-end gap-[16.97px] relative flex-[0_0_auto] mr-[-2.42px]">
              <div className="relative self-stretch h-[159.92px] mt-[-0.81px] font-semibold text-[#ffffff] text-[66.6px] text-right leading-[80.0px] [font-family:'Urbanist',Helvetica] tracking-[0]">
                Empowering Architects to Thrive
              </div>

              <div className="inline-flex items-center gap-[24.24px] relative flex-[0_0_auto]">
                <button className="all-[unset] box-border inline-flex items-center justify-center gap-[6.46px] px-[19.39px] py-[14.54px] relative flex-[0_0_auto] rounded-[27.47px] border-[0.81px] border-solid border-secondary-yellow">
                  <div className="relative w-fit mt-[-0.81px] [font-family:'Urbanist',Helvetica] font-medium text-secondary-yellow text-[14.5px] tracking-[0] leading-[21.8px] whitespace-nowrap">
                    Learn More
                  </div>
                </button>

                <div className="w-[179.38px] h-[54.94px] gap-[8.08px] px-[16.16px] py-[8.89px] bg-[#fcda86] rounded-[29.9px] flex items-center justify-center relative">
                  <div className="relative w-fit mt-[-0.22px] [font-family:'Urbanist',Helvetica] font-medium text-[#052e49] text-[24.2px] tracking-[0] leading-[36.4px] whitespace-nowrap">
                    Register Now
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="flex w-[109px] h-[38px] items-center justify-center gap-[8.08px] px-[18.58px] py-[1.62px] absolute top-0 left-[1236px] bg-[#fcda86] rounded-[27.47px]">
            <div className="relative w-fit [font-family:'Urbanist',Helvetica] font-medium text-[#052e49] text-[14.5px] tracking-[0] leading-[21.8px] whitespace-nowrap">
              Contact Us
            </div>
          </div>

          <div className="inline-flex items-center gap-[16.97px] absolute top-[13px] left-[847px]">
            <div className="relative w-fit mt-[-0.81px] [font-family:'Urbanist',Helvetica] font-bold text-[#fcda86] text-[14.5px] tracking-[0] leading-[21.8px] whitespace-nowrap">
              Home
            </div>

            <div className="relative w-fit mt-[-0.81px] [font-family:'Urbanist',Helvetica] font-medium text-[#fcda86] text-[14.5px] tracking-[0] leading-[21.8px] whitespace-nowrap">
              Services
            </div>

            <div className="relative w-fit mt-[-0.81px] [font-family:'Urbanist',Helvetica] font-medium text-[#fcda86] text-[14.5px] tracking-[0] leading-[21.8px] whitespace-nowrap">
              About us
            </div>

            <div className="relative w-fit mt-[-0.81px] [font-family:'Urbanist',Helvetica] font-medium text-[#fcda86] text-[14.5px] tracking-[0] leading-[21.8px] whitespace-nowrap">
              Resources
            </div>

            <div className="relative w-fit mt-[-0.81px] [font-family:'Urbanist',Helvetica] font-medium text-[#fcda86] text-[14.5px] tracking-[0] leading-[21.8px] whitespace-nowrap">
              Register
            </div>
          </div>

          <img
            className="absolute w-[41px] h-0.5 top-[34px] left-[845px]"
            alt="Line"
            src="./src/screens/Resource/img/line-1.svg"
          />

          <div className="flex flex-col w-8 items-center gap-[29.9px] absolute top-[88px] left-[1281px]">
            <div className="relative w-[32.32px] h-[32.32px] rounded-[16.16px] border-[0.81px] border-solid border-secondary-yellow">
              <div className="relative w-1.5 h-1.5 top-3 left-3 bg-secondary-yellow rounded-[3.23px]" />
            </div>

            <div className="relative w-[6.46px] h-[6.46px] bg-secondary-yellow rounded-[3.23px]" />

            <div className="relative w-[6.46px] h-[6.46px] bg-secondary-yellow rounded-[3.23px]" />

            <div className="relative w-[6.46px] h-[6.46px] bg-secondary-yellow rounded-[3.23px]" />
          </div>

          <div className="flex flex-col w-12 items-center gap-[17.78px] absolute top-[366px] left-1.5">
            <img
              className="relative w-[33.94px] h-[31.51px]"
              alt="Mask group"
              src="./src/screens/Resource/img/mask-group-3@2x.png"
            />

            <img
              className="relative w-[39.59px] h-[33.13px]"
              alt="Mask group"
              src="./src/screens/Resource/img/mask-group-4@2x.png"
            />

            <img
              className="relative w-[47.67px] h-[30.7px]"
              alt="Mask group"
              src="./src/screens/Resource/img/mask-group-5@2x.png"
            />

            <img
              className="relative w-[33.94px] h-[28.28px]"
              alt="Mask group"
              src="./src/screens/Resource/img/mask-group-6@2x.png"
            />

            <img
              className="relative w-[29.9px] h-[29.09px]"
              alt="Vector"
              src="./src/screens/Resource/img/vector.svg"
            />
          </div>
        </div>

        <div className="flex w-[1396px] h-[32.32px] items-center justify-center gap-[8.08px] px-[129.28px] py-[14.54px] absolute top-0 left-1 bg-[#052e49] border-b-[0.81px] [border-bottom-style:solid]">
          <img
            className="absolute w-[1396px] h-8 top-0 left-0"
            alt="Abstract design"
            src="./src/screens/Resource/img/abstract-design.svg"
          />

          <p className="relative w-fit mt-[-10.19px] mb-[-8.58px] [font-family:'Urbanist',Helvetica] font-medium text-[#ffffff] text-[14.5px] text-center tracking-[0] leading-[21.8px] whitespace-nowrap">
            ✨REGISTER early to get early benifits
          </p>

          <button className="all-[unset] box-border relative w-fit mt-[-10.19px] mb-[-8.58px] [font-family:'Urbanist',Helvetica] font-medium text-[#ffffff] text-[14.5px] text-center tracking-[0] leading-[21.8px] underline whitespace-nowrap">
            Learn More
          </button>

          <div className="inline-flex h-[25.05px] items-center gap-[6.46px] px-[19.39px] py-[12.93px] relative flex-[0_0_auto] mt-[-10.91px] mb-[-10.91px] bg-[#fcda86] rounded-[8.08px] border-[0.81px] border-solid border-[#052e49]">
            <div className="relative w-fit mt-[-12.21px] mb-[-10.60px] [font-family:'Urbanist',Helvetica] font-medium text-[#052e49] text-[14.5px] tracking-[0] leading-[21.8px] whitespace-nowrap">
              Register design firm
            </div>
          </div>

          <img
            className="absolute w-[26px] h-[26px] top-0.5 left-[1345px]"
            alt="Button"
            src="./src/screens/Resource/img/button.svg"
          />
        </div>

        <div className="absolute w-[1139px] h-[707px] top-[780px] left-[172px]">
          <div className="absolute w-[1139px] h-[707px] top-0 left-0">
            <div className="absolute w-[622px] h-36 top-[493px] left-[17px] bg-[#052e49b0] rounded-[22.84px_38.83px_38.83px_22.84px]" />

            <img
              className="absolute w-[638px] h-[682px] top-[25px] left-[501px] object-cover"
              alt="Hone PIC"
              src="./src/screens/Resource/img/hone-pic-2-2.png"
            />

            <div className="absolute w-[588px] top-0 left-0 font-semibold text-[#052e49] text-[28.3px] leading-[42.4px] whitespace-nowrap [font-family:'Urbanist',Helvetica] tracking-[0]">
              About us
            </div>

            <div className="absolute w-[507px] top-[55px] left-[21px] font-normal text-primary-blue text-[56.6px] leading-[68.0px] [font-family:'Urbanist',Helvetica] tracking-[0]">
              Empowering Architects to thrive
            </div>

            <div className="flex flex-col w-[622px] h-[148px] items-start gap-[7.8px] pl-[32.77px] pr-[31.21px] pt-[32.77px] pb-[44.48px] absolute top-[492px] left-[17px] bg-[#052e4980] rounded-[23.41px_39.8px_39.8px_23.41px] backdrop-blur-[7.8px] backdrop-brightness-[100%] [-webkit-backdrop-filter:blur(7.8px)_brightness(100%)]">
              <div className="flex flex-wrap w-[579.78px] h-[82.71px] items-center gap-[0px_12.7px] relative mb-[-11.70px] mr-[-21.85px]">
                <div className="flex flex-col w-[122.77px] items-center gap-[4.94px] relative">
                  <div className="relative self-stretch h-[25.4px] mt-[-0.71px] [font-family:'Urbanist',Helvetica] font-bold text-[#fcda86] text-[22.1px] text-center tracking-[0] leading-[normal] whitespace-nowrap">
                    25+
                  </div>

                  <div className="relative self-stretch [font-family:'Urbanist',Helvetica] font-normal text-[#fcda86] text-[11.3px] text-center tracking-[0] leading-[normal]">
                    Projects Completed
                  </div>
                </div>

                <img
                  className="relative w-[3.53px] h-[35.28px]"
                  alt="Line"
                  src="./src/screens/Resource/img/line-2.svg"
                />

                <div className="flex flex-col w-[122.77px] items-center gap-[4.94px] relative">
                  <div className="relative self-stretch h-[25.4px] mt-[-0.71px] [font-family:'Urbanist',Helvetica] font-bold text-[#fcda86] text-[22.1px] text-center tracking-[0] leading-[normal] whitespace-nowrap">
                    30+
                  </div>

                  <div className="relative self-stretch [font-family:'Urbanist',Helvetica] font-normal text-[#fcda86] text-[11.3px] text-center tracking-[0] leading-[normal]">
                    Firms Registered
                  </div>
                </div>

                <img
                  className="relative w-[3.53px] h-[35.28px]"
                  alt="Line"
                  src="./src/screens/Resource/img/line-3.svg"
                />

                <div className="flex flex-col w-[122.77px] items-center gap-[4.94px] relative">
                  <div className="relative self-stretch h-[25.4px] mt-[-0.71px] [font-family:'Urbanist',Helvetica] font-bold text-[#fcda86] text-[22.1px] text-center tracking-[0] leading-[normal] whitespace-nowrap">
                    50+
                  </div>

                  <div className="relative self-stretch [font-family:'Urbanist',Helvetica] font-normal text-[#fcda86] text-[11.3px] text-center tracking-[0] leading-[normal]">
                    Users
                  </div>
                </div>

                <img
                  className="relative w-[3.53px] h-[35.28px]"
                  alt="Line"
                  src="./src/screens/Resource/img/line-4.svg"
                />

                <div className="flex flex-col w-[122.77px] items-center gap-[4.94px] relative">
                  <div className="relative self-stretch h-[25.4px] mt-[-0.71px] [font-family:'Urbanist',Helvetica] font-bold text-[#fcda86] text-[22.1px] text-center tracking-[0] leading-[normal] whitespace-nowrap">
                    100+
                  </div>

                  <div className="relative self-stretch [font-family:'Urbanist',Helvetica] font-normal text-[#fcda86] text-[11.3px] text-center tracking-[0] leading-[normal]">
                    Project Applications
                  </div>
                </div>
              </div>
            </div>
          </div>

          <p className="absolute w-[405px] top-[211px] left-[21px] font-medium text-[#052e49] text-[18.7px] leading-[28.1px] [font-family:'Urbanist',Helvetica] tracking-[0]">
            At Cre8ivy, we are dedicated to transforming the way architects
            connect with opportunities. We know that the path to success in this
            industry is filled with challenges – from securing reliable clients
            to navigating trust, tight budgets and deadlines.
          </p>

          <div className="flex w-[368px] h-[53px] items-center gap-[23.41px] absolute top-[406px] left-[17px]">
            <div className="w-[173.23px] h-[53.06px] gap-[7.8px] px-[15.61px] py-[8.58px] rounded-[28.87px] border-[1.56px] border-solid border-primary-blue flex items-center justify-center relative">
              <div className="relative w-fit mt-[-1.11px] [font-family:'Urbanist',Helvetica] font-medium text-primary-blue text-[23.4px] tracking-[0] leading-[35.1px] whitespace-nowrap">
                Read more
              </div>
            </div>

            <div className="flex w-[173.23px] h-[53.06px] items-center justify-center gap-[7.8px] px-[15.61px] py-[8.58px] relative mr-[-2.34px] bg-[#fcda86] rounded-[28.87px]">
              <div className="relative w-fit mt-[-0.33px] [font-family:'Urbanist',Helvetica] font-medium text-[#052e49] text-[23.4px] tracking-[0] leading-[35.1px] whitespace-nowrap">
                REGISTER
              </div>
            </div>
          </div>
        </div>

        <p className="absolute w-[701px] top-[1678px] left-[352px] font-medium text-[#052e49] text-[18.7px] text-center leading-[28.1px] [font-family:'Urbanist',Helvetica] tracking-[0]">
          Cre8ivy offers a comprehensive platform for interior design firms to
          enhance their business and client engagement. Here’s how we can help:
        </p>

        <div className="absolute w-[246px] top-[1632px] left-[579px] font-semibold text-[#052e49] text-[28.3px] text-center leading-[42.4px] whitespace-nowrap [font-family:'Urbanist',Helvetica] tracking-[0]">
          Our Services
        </div>

        <div className="flex flex-wrap w-[1000px] items-start gap-[25px_40.4px] absolute top-[1795px] left-[246px]">
          <div className="relative w-[291.65px] h-[298.96px]">
            <div className="relative w-72 h-[299px] bg-[url(/src/screens/Resource/img/rectangle-17.svg)] bg-[100%_100%]">
              <p className="absolute w-[158px] h-12 top-[70px] left-[110px] font-semibold text-secondary-yellow text-right [font-family:'Urbanist',Helvetica] text-[10.3px] tracking-[0] leading-[normal]">
                Showcase your portfolio to a broader audience of potential
                clients, ensuring your work gets the recognition it deserves.
              </p>

              <div className="absolute w-[184px] h-[23px] top-[25px] left-[47px] [font-family:'Urbanist',Helvetica] font-semibold text-[#ffffff] text-[17.6px] text-center tracking-[0] leading-[normal]">
                Increased Visibility
              </div>

              <div className="absolute w-[79px] h-[38px] top-[154px] left-[190px]">
                <div className="absolute w-[38px] h-[38px] top-0 left-[41px] rounded-[18.99px] border-[1.62px] border-solid border-secondary-yellow" />

                <img
                  className="absolute w-[65px] h-3 top-[13px] left-0"
                  alt="Arrow"
                  src="./src/screens/Resource/img/arrow-1.svg"
                />
              </div>

              <img
                className="absolute w-[100px] h-[67px] top-[215px] left-[23px]"
                alt="Vector"
                src="./src/screens/Resource/img/vector-1.svg"
              />
            </div>
          </div>

          <div className="relative w-[291.65px] h-[298.96px]">
            <div className="relative w-72 h-[299px] bg-[url(/src/screens/Resource/img/rectangle-17.svg)] bg-[100%_100%]">
              <p className="absolute w-[158px] h-[61px] top-[70px] left-[110px] [font-family:'Urbanist',Helvetica] font-semibold text-secondary-yellow text-[10.3px] text-right tracking-[0] leading-[normal]">
                Utilize our project management tools to streamline communication
                and collaboration with clients, ensuring clarity and timely
                feedback.
              </p>

              <div className="absolute w-[184px] h-[23px] top-[25px] left-[47px] [font-family:'Urbanist',Helvetica] font-semibold text-[#ffffff] text-[17.6px] text-center tracking-[0] leading-[normal]">
                Efficient Collaboration
              </div>

              <div className="absolute w-[79px] h-[38px] top-[154px] left-[190px]">
                <div className="absolute w-[38px] h-[38px] top-0 left-[41px] rounded-[18.99px] border-[1.62px] border-solid border-secondary-yellow" />

                <img
                  className="absolute w-[65px] h-3 top-[13px] left-0"
                  alt="Arrow"
                  src="./src/screens/Resource/img/arrow-1.svg"
                />
              </div>

              <img
                className="absolute w-[105px] h-[63px] top-[215px] left-[22px]"
                alt="Vector"
                src="./src/screens/Resource/img/vector-2.svg"
              />
            </div>
          </div>

          <div className="relative w-[291.65px] h-[298.96px] mr-[-4.00px]">
            <div className="relative w-72 h-[299px] bg-[url(/src/screens/Resource/img/rectangle-17.svg)] bg-[100%_100%]">
              <p className="absolute w-[158px] h-[61px] top-[70px] left-[110px] [font-family:'Urbanist',Helvetica] font-semibold text-secondary-yellow text-[10.3px] text-right tracking-[0] leading-[normal]">
                Build trust with clients through our secure payment system,
                which ensures fast and guaranteed payments for your services.
              </p>

              <div className="absolute w-[262px] h-[23px] top-[25px] left-2 font-semibold text-[#ffffff] [font-family:'Urbanist',Helvetica] text-[17.6px] text-center tracking-[0] leading-[normal]">
                Trust and Accountability
              </div>

              <div className="absolute w-[79px] h-[38px] top-[154px] left-[190px]">
                <div className="absolute w-[38px] h-[38px] top-0 left-[41px] rounded-[18.99px] border-[1.62px] border-solid border-secondary-yellow" />

                <img
                  className="absolute w-[65px] h-3 top-[13px] left-0"
                  alt="Arrow"
                  src="./src/screens/Resource/img/arrow-1.svg"
                />
              </div>

              <img
                className="absolute w-[63px] h-[72px] top-[210px] left-[43px]"
                alt="Vector"
                src="./src/screens/Resource/img/vector-3.svg"
              />
            </div>
          </div>

          <div className="relative w-[291.65px] h-[298.96px]">
            <div className="relative w-72 h-[299px] bg-[url(/src/screens/Resource/img/rectangle-17.svg)] bg-[100%_100%]">
              <p className="absolute w-[158px] h-[61px] top-[70px] left-[110px] [font-family:'Urbanist',Helvetica] font-semibold text-secondary-yellow text-[10.3px] text-right tracking-[0] leading-[normal]">
                Connect with other professionals in the industry to explore
                partnerships, share insights, and grow your business.
              </p>

              <div className="absolute w-[262px] h-[23px] top-[25px] left-2 font-semibold text-[#ffffff] [font-family:'Urbanist',Helvetica] text-[17.6px] text-center tracking-[0] leading-[normal]">
                Networking Opportunities
              </div>

              <div className="absolute w-[79px] h-[38px] top-[154px] left-[190px]">
                <div className="absolute w-[38px] h-[38px] top-0 left-[41px] rounded-[18.99px] border-[1.62px] border-solid border-secondary-yellow" />

                <img
                  className="absolute w-[65px] h-3 top-[13px] left-0"
                  alt="Arrow"
                  src="./src/screens/Resource/img/arrow-1.svg"
                />
              </div>

              <img
                className="absolute w-[100px] h-[70px] top-[212px] left-[23px]"
                alt="Vector"
                src="./src/screens/Resource/img/vector-4.svg"
              />
            </div>
          </div>

          <div className="relative w-[291.65px] h-[298.96px]">
            <div className="relative w-72 h-[299px] bg-[url(/src/screens/Resource/img/rectangle-17.svg)] bg-[100%_100%]">
              <p className="absolute w-[158px] h-[61px] top-[70px] left-[110px] [font-family:'Urbanist',Helvetica] font-semibold text-secondary-yellow text-[10.3px] text-right tracking-[0] leading-[normal]">
                Access a wealth of resources, including design templates, market
                insights, and industry news, all tailored to the needs of 
                interior design firms.
              </p>

              <div className="absolute w-[262px] h-[23px] top-[25px] left-2 font-semibold text-[#ffffff] [font-family:'Urbanist',Helvetica] text-[17.6px] text-center tracking-[0] leading-[normal]">
                Resource Hub
              </div>

              <div className="absolute w-[79px] h-[38px] top-[154px] left-[190px]">
                <div className="absolute w-[38px] h-[38px] top-0 left-[41px] rounded-[18.99px] border-[1.62px] border-solid border-secondary-yellow" />

                <img
                  className="absolute w-[65px] h-3 top-[13px] left-0"
                  alt="Arrow"
                  src="./src/screens/Resource/img/arrow-3.svg"
                />
              </div>

              <img
                className="absolute w-[65px] h-[74px] top-52 left-[45px]"
                alt="Vector"
                src="./src/screens/Resource/img/vector-5.svg"
              />
            </div>
          </div>

          <div className="flex flex-col w-[287.65px] h-[298.96px] items-center gap-[3.23px] px-[9.7px] py-[71.1px] relative bg-[#fcda86] rounded-[25.05px]">
            <div className="relative w-[261.79px] h-[22.62px] mt-[-1.62px] font-bold text-[#052e49] [font-family:'Urbanist',Helvetica] text-[17.6px] text-center tracking-[0] leading-[normal]">
              We provide the Best
            </div>

            <p className="relative w-[157.56px] h-[60.6px] font-medium text-paragraph-color text-center [font-family:'Urbanist',Helvetica] text-[10.3px] tracking-[0] leading-[normal]">
              Our team is here to make your life easier. join us now and explore
            </p>

            <div className="flex w-[173.23px] h-[53.06px] items-center justify-center gap-[7.8px] px-[15.61px] py-[8.58px] relative bg-primary-blue rounded-[28.87px]">
              <div className="relative w-fit mt-[-0.33px] [font-family:'Urbanist',Helvetica] font-medium text-secondary-yellow text-[23.4px] tracking-[0] leading-[35.1px] whitespace-nowrap">
                Join Us
              </div>
            </div>
          </div>
        </div>

        <div className="flex flex-col w-[952px] items-start gap-[47.7px] absolute top-[2699px] left-[229px]">
          <div className="flex items-end gap-[119.24px] relative self-stretch w-full flex-[0_0_auto]">
            <div className="flex flex-col items-start gap-[8.35px] relative flex-1 grow">
              <div className="relative self-stretch mt-[-0.60px] font-semibold text-primary-blue text-[28.6px] leading-[42.9px] [font-family:'Urbanist',Helvetica] tracking-[0]">
                What Our Clients Say
              </div>

              <p className="relative self-stretch font-medium text-grey-60 text-[18.8px] leading-[28.2px] [font-family:'Urbanist',Helvetica] tracking-[0]">
                Read the success stories and heartfelt testimonials from our
                valued clients. Discover why they chose Cre8ivy for their
                architecture needs.
              </p>

              <img
                className="absolute w-[41px] h-[18px] -top-6 -left-3"
                alt="Abstract design"
                src="./src/screens/Resource/img/abstract-design-1.svg"
              />
            </div>

            <button className="all-[unset] box-border inline-flex items-center gap-[5.62px] px-[16.87px] py-[12.65px] relative flex-[0_0_auto] bg-primary-blue rounded-[29.52px] border-[0.7px] border-solid border-primary-blue">
              <div className="relative w-fit mt-[-0.70px] [font-family:'Urbanist',Helvetica] font-medium text-secondary-yellow text-[12.6px] tracking-[0] leading-[19.0px] whitespace-nowrap">
                View All Testimonials
              </div>
            </button>
          </div>

          <div className="flex flex-col items-start gap-[29.81px] relative self-stretch w-full flex-[0_0_auto]">
            <div className="flex items-start gap-[17.89px] relative self-stretch w-full flex-[0_0_auto]">
              <div className="flex flex-col items-start gap-[23.85px] p-[29.81px] relative flex-1 grow bg-primary-blue rounded-[25.05px] border-[0.6px] border-solid border-primary-blue">
                <div className="inline-flex items-start gap-[5.96px] relative flex-[0_0_auto]">
                  <div className="inline-flex items-start gap-[5.96px] p-[5.96px] relative flex-[0_0_auto] bg-heading-color rounded-[59.62px] border-[0.6px] border-solid border-heading-color">
                    <img
                      className="relative w-[12.7px] h-[12.14px]"
                      alt="Shape"
                      src="./src/screens/Resource/img/shape.svg"
                    />
                  </div>

                  <div className="inline-flex items-start gap-[5.96px] p-[5.96px] relative flex-[0_0_auto] bg-heading-color rounded-[59.62px] border-[0.6px] border-solid border-heading-color">
                    <img
                      className="relative w-[12.7px] h-[12.14px]"
                      alt="Shape"
                      src="./src/screens/Resource/img/shape-1.svg"
                    />
                  </div>

                  <div className="inline-flex items-start gap-[5.96px] p-[5.96px] relative flex-[0_0_auto] bg-heading-color rounded-[59.62px] border-[0.6px] border-solid border-heading-color">
                    <img
                      className="relative w-[12.7px] h-[12.14px]"
                      alt="Shape"
                      src="./src/screens/Resource/img/shape-2.svg"
                    />
                  </div>

                  <div className="inline-flex items-start gap-[5.96px] p-[5.96px] relative flex-[0_0_auto] bg-heading-color rounded-[59.62px] border-[0.6px] border-solid border-heading-color">
                    <img
                      className="relative w-[12.7px] h-[12.14px]"
                      alt="Shape"
                      src="./src/screens/Resource/img/shape-3.svg"
                    />
                  </div>

                  <div className="inline-flex items-start gap-[5.96px] p-[5.96px] relative flex-[0_0_auto] bg-heading-color rounded-[59.62px] border-[0.6px] border-solid border-heading-color">
                    <img
                      className="relative w-[12.7px] h-[12.14px]"
                      alt="Shape"
                      src="./src/screens/Resource/img/shape-4.svg"
                    />
                  </div>
                </div>

                <div className="flex flex-col items-start gap-[8.35px] relative self-stretch w-full flex-[0_0_auto]">
                  <div className="relative self-stretch mt-[-0.60px] [font-family:'Urbanist',Helvetica] font-semibold text-white text-[14.3px] tracking-[0] leading-[21.5px]">
                    Exceptional Service!
                  </div>

                  <p className="relative self-stretch [font-family:'Urbanist',Helvetica] font-medium text-secondary-yellow text-[10.7px] tracking-[0] leading-[16.1px]">
                    Our experience with Estatein was outstanding. Their
                    team&#39;s dedication and professionalism made building our
                    dream home a breeze. Highly recommended!
                  </p>
                </div>

                <div className="flex items-center gap-[7.15px] relative self-stretch w-full flex-[0_0_auto]">
                  <img
                    className="relative w-[35.77px] h-[35.77px] object-cover"
                    alt="Profile"
                    src="./src/screens/Resource/img/profile@2x.png"
                  />

                  <div className="flex flex-col items-start gap-[1.19px] relative flex-1 grow">
                    <div className="relative self-stretch mt-[-0.60px] [font-family:'Urbanist',Helvetica] font-medium text-secondary-yellow text-[11.9px] tracking-[0] leading-[17.9px]">
                      Wade Warren
                    </div>

                    <div className="relative self-stretch [font-family:'Urbanist',Helvetica] font-medium text-grey-60 text-[10.7px] tracking-[0] leading-[16.1px]">
                      USA, California
                    </div>
                  </div>
                </div>
              </div>

              <div className="flex flex-col items-start gap-[23.85px] p-[29.81px] relative flex-1 grow bg-primary-blue rounded-[25.05px] border-[0.6px] border-solid border-primary-blue">
                <div className="inline-flex items-start gap-[5.96px] relative flex-[0_0_auto]">
                  <div className="inline-flex items-start gap-[5.96px] p-[5.96px] relative flex-[0_0_auto] bg-heading-color rounded-[59.62px] border-[0.6px] border-solid border-heading-color">
                    <img
                      className="relative w-[12.7px] h-[12.14px]"
                      alt="Shape"
                      src="./src/screens/Resource/img/shape-5.svg"
                    />
                  </div>

                  <div className="inline-flex items-start gap-[5.96px] p-[5.96px] relative flex-[0_0_auto] bg-heading-color rounded-[59.62px] border-[0.6px] border-solid border-heading-color">
                    <img
                      className="relative w-[12.7px] h-[12.14px]"
                      alt="Shape"
                      src="./src/screens/Resource/img/shape-6.svg"
                    />
                  </div>

                  <div className="inline-flex items-start gap-[5.96px] p-[5.96px] relative flex-[0_0_auto] bg-heading-color rounded-[59.62px] border-[0.6px] border-solid border-heading-color">
                    <img
                      className="relative w-[12.7px] h-[12.14px]"
                      alt="Shape"
                      src="./src/screens/Resource/img/shape-7.svg"
                    />
                  </div>

                  <div className="inline-flex items-start gap-[5.96px] p-[5.96px] relative flex-[0_0_auto] bg-heading-color rounded-[59.62px] border-[0.6px] border-solid border-heading-color">
                    <img
                      className="relative w-[12.7px] h-[12.14px]"
                      alt="Shape"
                      src="./src/screens/Resource/img/shape-8.svg"
                    />
                  </div>

                  <div className="inline-flex items-start gap-[5.96px] p-[5.96px] relative flex-[0_0_auto] bg-heading-color rounded-[59.62px] border-[0.6px] border-solid border-heading-color">
                    <img
                      className="relative w-[12.7px] h-[12.14px]"
                      alt="Shape"
                      src="./src/screens/Resource/img/shape-9.svg"
                    />
                  </div>
                </div>

                <div className="flex flex-col items-start gap-[8.35px] relative self-stretch w-full flex-[0_0_auto]">
                  <div className="relative self-stretch mt-[-0.60px] [font-family:'Urbanist',Helvetica] font-semibold text-white text-[14.3px] tracking-[0] leading-[21.5px]">
                    Efficient and Reliable
                  </div>

                  <p className="relative self-stretch [font-family:'Urbanist',Helvetica] font-medium text-secondary-yellow text-[10.7px] tracking-[0] leading-[16.1px]">
                    Cre8ivy provided us with top-notch service. They helped us
                    deal with all the hassle and quality assurance. We
                    couldn&#39;t be happier with the results.
                  </p>
                </div>

                <div className="flex items-center gap-[7.15px] relative self-stretch w-full flex-[0_0_auto]">
                  <img
                    className="relative w-[35.77px] h-[35.77px] object-cover"
                    alt="Profile"
                    src="./src/screens/Resource/img/profile-1@2x.png"
                  />

                  <div className="flex flex-col items-start gap-[1.19px] relative flex-1 grow">
                    <div className="relative self-stretch mt-[-0.60px] [font-family:'Urbanist',Helvetica] font-medium text-secondary-yellow text-[11.9px] tracking-[0] leading-[17.9px]">
                      Emelie Thomson
                    </div>

                    <div className="relative self-stretch [font-family:'Urbanist',Helvetica] font-medium text-grey-60 text-[10.7px] tracking-[0] leading-[16.1px]">
                      USA, Florida
                    </div>
                  </div>
                </div>
              </div>

              <div className="flex flex-col items-start gap-[23.85px] p-[29.81px] relative flex-1 grow bg-primary-blue rounded-[25.05px] border-[0.6px] border-solid border-primary-blue">
                <div className="inline-flex items-start gap-[5.96px] relative flex-[0_0_auto]">
                  <div className="inline-flex items-start gap-[5.96px] p-[5.96px] relative flex-[0_0_auto] bg-heading-color rounded-[59.62px] border-[0.6px] border-solid border-heading-color">
                    <img
                      className="relative w-[12.7px] h-[12.14px]"
                      alt="Shape"
                      src="./src/screens/Resource/img/shape-10.svg"
                    />
                  </div>

                  <div className="inline-flex items-start gap-[5.96px] p-[5.96px] relative flex-[0_0_auto] bg-heading-color rounded-[59.62px] border-[0.6px] border-solid border-heading-color">
                    <img
                      className="relative w-[12.7px] h-[12.14px]"
                      alt="Shape"
                      src="./src/screens/Resource/img/shape-11.svg"
                    />
                  </div>

                  <div className="inline-flex items-start gap-[5.96px] p-[5.96px] relative flex-[0_0_auto] bg-heading-color rounded-[59.62px] border-[0.6px] border-solid border-heading-color">
                    <img
                      className="relative w-[12.7px] h-[12.14px]"
                      alt="Shape"
                      src="./src/screens/Resource/img/shape-12.svg"
                    />
                  </div>

                  <div className="inline-flex items-start gap-[5.96px] p-[5.96px] relative flex-[0_0_auto] bg-heading-color rounded-[59.62px] border-[0.6px] border-solid border-heading-color">
                    <img
                      className="relative w-[12.7px] h-[12.14px]"
                      alt="Shape"
                      src="./src/screens/Resource/img/shape-13.svg"
                    />
                  </div>

                  <div className="inline-flex items-start gap-[5.96px] p-[5.96px] relative flex-[0_0_auto] bg-heading-color rounded-[59.62px] border-[0.6px] border-solid border-heading-color">
                    <img
                      className="relative w-[12.7px] h-[12.14px]"
                      alt="Shape"
                      src="./src/screens/Resource/img/shape-14.svg"
                    />
                  </div>
                </div>

                <div className="flex flex-col items-start gap-[8.35px] relative self-stretch w-full flex-[0_0_auto]">
                  <div className="relative self-stretch mt-[-0.60px] [font-family:'Urbanist',Helvetica] font-semibold text-white text-[14.3px] tracking-[0] leading-[21.5px]">
                    Trusted Portal
                  </div>

                  <p className="relative self-stretch [font-family:'Urbanist',Helvetica] font-medium text-secondary-yellow text-[10.7px] tracking-[0] leading-[16.1px]">
                    “Cre8ivy has transformed the way we connect with clients.
                    The platform’s tools and resources have streamlined our
                    processes, allowing us to focus more on creativity and
                    design.”
                  </p>
                </div>

                <div className="flex items-center gap-[7.15px] relative self-stretch w-full flex-[0_0_auto]">
                  <img
                    className="relative w-[35.77px] h-[35.77px] object-cover"
                    alt="Profile"
                    src="./src/screens/Resource/img/profile-2@2x.png"
                  />

                  <div className="flex flex-col items-start gap-[1.19px] relative flex-1 grow">
                    <div className="relative self-stretch mt-[-0.60px] [font-family:'Urbanist',Helvetica] font-medium text-secondary-yellow text-[11.9px] tracking-[0] leading-[17.9px]">
                      John Mans
                    </div>

                    <div className="relative self-stretch [font-family:'Urbanist',Helvetica] font-medium text-grey-60 text-[10.7px] tracking-[0] leading-[16.1px]">
                      USA, Nevada
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="flex items-start justify-between pt-[11.92px] pb-0 px-0 relative self-stretch w-full flex-[0_0_auto] border-t-[0.6px] [border-top-style:solid] border-primary-blue">
              <p className="relative w-fit mt-[-0.60px] [font-family:'Urbanist',Helvetica] font-medium text-transparent text-[11.9px] tracking-[0] leading-[17.9px] whitespace-nowrap">
                <span className="text-[#fcda86]">01</span>

                <span className="text-[#999999]"> of 01</span>
              </p>

              <img
                className="relative flex-[0_0_auto]"
                alt="Buttons container"
                src="./src/screens/Resource/img/buttons-container.svg"
              />
            </div>
          </div>
        </div>

        <div className="absolute w-[1447px] h-[527px] top-[3339px] left-0.5">
          <div className="flex flex-col w-[1447px] h-[446px] items-start gap-[8.08px] px-[227.05px] py-[92.92px] absolute top-[81px] left-0">
            <div className="absolute w-[1447px] h-[446px] top-0 left-0">
              <div className="relative w-[1406px] h-[446px]">
                <div className="absolute w-[1406px] h-[444px] top-0 left-0 bg-[#052e49]" />

                <img
                  className="absolute w-[536px] h-[318px] top-32 left-[861px]"
                  alt="Abstract design"
                  src="./src/screens/Resource/img/abstract-design-2.svg"
                />
              </div>
            </div>

            <div className="relative w-[970.57px] h-[251.19px]">
              <div className="flex flex-col w-[702px] items-start gap-[9.91px] absolute top-0 left-0">
                <div className="relative self-stretch mt-[-0.71px] font-semibold text-[#ffffff] text-[25.1px] leading-[37.6px] [font-family:'Urbanist',Helvetica] tracking-[0]">
                  Pre-Registration
                </div>

                <p className="relative self-stretch font-normal text-secondary-yellow text-[17px] leading-[25.5px] [font-family:'Urbanist',Helvetica] tracking-[0]">
                  <span className="font-medium">
                    Join Cre8ivy and become part of a growing community of
                    interior design professionals. By registering your firm,
                    you’ll gain access to exclusive opportunities and resources
                    designed to elevate your business. <br />
                    <br />
                    By registering Early you will recieve
                    <br />
                  </span>

                  <span className="font-bold">
                    Be among the first to access exclusive projects.
                    <br />
                    Receive a complimentary feature on our platform for one
                    month.
                    <br />
                    Gain early access to our resource hub.
                  </span>
                </p>
              </div>

              <button className="all-[unset] box-border inline-flex items-center gap-[8.62px] px-[25.85px] py-[19.38px] absolute top-[183px] left-[806px] bg-secondary-yellow rounded-[33.39px] border-[2.15px] border-solid border-secondary-yellow">
                <div className="relative w-fit mt-[-2.15px] [font-family:'Urbanist',Helvetica] font-medium text-[#052e49] text-[19.4px] tracking-[0] leading-[29.1px] whitespace-nowrap">
                  Register Now
                </div>
              </button>
            </div>
          </div>

          <img
            className="absolute w-[316px] h-[316px] top-[41px] left-[937px] object-cover"
            alt="Reg"
            src="./src/screens/Resource/img/reg-2@2x.png"
          />

          <img
            className="absolute w-[246px] h-[168px] top-0 left-[52px]"
            alt="Ellipse"
            src="./src/screens/Resource/img/ellipse-9.svg"
          />

          <div className="flex w-[141px] h-[141px] items-start gap-[12.85px] p-[38.56px] absolute top-3 left-[103px] bg-primary-blue rounded-[128.55px] overflow-hidden border-[1.29px] border-solid border-secondary-yellow">
            <div className="relative w-[64.27px] h-[64.27px] bg-secondary-yellow rounded-[71.99px] border-[1.29px] border-solid border-[#072c43]" />

            <img
              className="absolute w-[27px] h-[27px] top-[57px] left-[57px]"
              alt="Icon"
              src="./src/screens/Resource/img/icon-2.svg"
            />

            <div className="absolute w-44 h-[116px] top-[13px] left-[13px]">
              <div className="absolute top-[102px] left-[58px] rotate-[172.02deg] [font-family:'Urbanist',Helvetica] font-semibold text-secondary-yellow text-[12.1px] tracking-[0] leading-[normal] whitespace-nowrap">
                ✨
              </div>

              <div className="absolute top-[100px] left-[46px] rotate-[-171.37deg] [font-family:'Urbanist',Helvetica] font-semibold text-secondary-yellow text-[12.1px] tracking-[0] leading-[normal]">
                D
              </div>

              <div className="absolute top-[98px] left-[39px] rotate-[-160.43deg] [font-family:'Urbanist',Helvetica] font-semibold text-secondary-yellow text-[12.1px] tracking-[0] leading-[normal]">
                i
              </div>

              <div className="absolute w-10 h-[102px] top-[9px] left-0">
                <div className="absolute top-[86px] left-[31px] rotate-[-151.52deg] [font-family:'Urbanist',Helvetica] font-semibold text-secondary-yellow text-[12.1px] tracking-[0] leading-[normal]">
                  s
                </div>

                <div className="absolute w-[33px] h-[90px] top-1.5 left-0">
                  <div className="absolute top-[75px] left-[22px] rotate-[-140.17deg] [font-family:'Urbanist',Helvetica] font-semibold text-secondary-yellow text-[12.1px] tracking-[0] leading-[normal]">
                    c
                  </div>

                  <div className="absolute w-[26px] h-[72px] top-2.5 left-0">
                    <div className="absolute top-[57px] left-[15px] rotate-[-127.61deg] [font-family:'Urbanist',Helvetica] font-semibold text-secondary-yellow text-[12.1px] tracking-[0] leading-[normal]">
                      o
                    </div>

                    <div className="absolute top-[47px] left-[9px] rotate-[-115.05deg] [font-family:'Urbanist',Helvetica] font-semibold text-secondary-yellow text-[12.1px] tracking-[0] leading-[normal]">
                      v
                    </div>

                    <div className="absolute top-[37px] left-[5px] rotate-[-102.50deg] [font-family:'Urbanist',Helvetica] font-semibold text-secondary-yellow text-[12.1px] tracking-[0] leading-[normal]">
                      e
                    </div>

                    <div className="absolute top-[27px] left-[5px] rotate-[-91.15deg] [font-family:'Urbanist',Helvetica] font-semibold text-secondary-yellow text-[12.1px] tracking-[0] leading-[normal]">
                      r
                    </div>

                    <div className="absolute top-[19px] left-1.5 rotate-[-82.24deg] [font-family:'Urbanist',Helvetica] font-semibold text-secondary-yellow text-[12.1px] tracking-[0] leading-[normal]">
                      {""}
                    </div>

                    <div className="absolute top-2.5 left-1.5 rotate-[-71.30deg] [font-family:'Urbanist',Helvetica] font-semibold text-secondary-yellow text-[12.1px] tracking-[0] leading-[normal]">
                      Y
                    </div>

                    <div className="absolute -top-px left-3 rotate-[-57.93deg] [font-family:'Urbanist',Helvetica] font-semibold text-secondary-yellow text-[12.1px] tracking-[0] leading-[normal]">
                      o
                    </div>
                  </div>

                  <div className="absolute top-0 left-[19px] rotate-[-45.37deg] [font-family:'Urbanist',Helvetica] font-semibold text-secondary-yellow text-[12.1px] tracking-[0] leading-[normal]">
                    u
                  </div>
                </div>

                <div className="absolute top-0 left-[27px] rotate-[-34.44deg] [font-family:'Urbanist',Helvetica] font-semibold text-secondary-yellow text-[12.1px] tracking-[0] leading-[normal]">
                  r
                </div>
              </div>

              <div className="absolute top-[5px] left-[35px] rotate-[-25.52deg] [font-family:'Urbanist',Helvetica] font-semibold text-secondary-yellow text-[12.1px] tracking-[0] leading-[normal]">
                {""}
              </div>

              <div className="absolute top-0.5 left-[41px] rotate-[-14.18deg] [font-family:'Urbanist',Helvetica] font-semibold text-secondary-yellow text-[12.1px] tracking-[0] leading-[normal]">
                D
              </div>

              <div className="absolute top-0 left-[54px] rotate-[-2.03deg] [font-family:'Urbanist',Helvetica] font-semibold text-secondary-yellow text-[12.1px] tracking-[0] leading-[normal]">
                r
              </div>

              <div className="absolute top-px left-[63px] rotate-[9.32deg] [font-family:'Urbanist',Helvetica] font-semibold text-secondary-yellow text-[12.1px] tracking-[0] leading-[normal]">
                e
              </div>

              <div className="absolute top-1 left-[73px] rotate-[22.28deg] [font-family:'Urbanist',Helvetica] font-semibold text-secondary-yellow text-[12.1px] tracking-[0] leading-[normal]">
                a
              </div>

              <div className="absolute w-[38px] h-[100px] top-[9px] left-[78px]">
                <div className="absolute top-0.5 left-[5px] rotate-[37.27deg] [font-family:'Urbanist',Helvetica] font-semibold text-secondary-yellow text-[12.1px] tracking-[0] leading-[normal]">
                  m
                </div>

                <div className="absolute w-[33px] h-[86px] top-[11px] left-[5px]">
                  <div className="absolute -top-0.5 left-3 rotate-[49.83deg] [font-family:'Urbanist',Helvetica] font-semibold text-secondary-yellow text-[12.1px] tracking-[0] leading-[normal]">
                    {""}
                  </div>

                  <div className="absolute w-[26px] h-[71px] top-[7px] left-[7px]">
                    <div className="absolute -top-px left-[9px] rotate-[59.96deg] [font-family:'Urbanist',Helvetica] font-semibold text-secondary-yellow text-[12.1px] tracking-[0] leading-[normal]">
                      P
                    </div>

                    <div className="absolute top-2 left-3.5 rotate-[70.90deg] [font-family:'Urbanist',Helvetica] font-semibold text-secondary-yellow text-[12.1px] tracking-[0] leading-[normal]">
                      r
                    </div>

                    <div className="absolute top-[17px] left-[15px] rotate-[82.24deg] [font-family:'Urbanist',Helvetica] font-semibold text-secondary-yellow text-[12.1px] tracking-[0] leading-[normal]">
                      o
                    </div>

                    <div className="absolute top-[29px] left-[15px] rotate-[95.20deg] [font-family:'Urbanist',Helvetica] font-semibold text-secondary-yellow text-[12.1px] tracking-[0] leading-[normal]">
                      p
                    </div>

                    <div className="absolute top-10 left-[13px] rotate-[108.17deg] [font-family:'Urbanist',Helvetica] font-semibold text-secondary-yellow text-[12.1px] tracking-[0] leading-[normal]">
                      e
                    </div>

                    <div className="absolute top-[49px] left-2.5 rotate-[119.51deg] [font-family:'Urbanist',Helvetica] font-semibold text-secondary-yellow text-[12.1px] tracking-[0] leading-[normal]">
                      r
                    </div>

                    <div className="absolute top-[57px] left-[5px] rotate-[129.23deg] [font-family:'Urbanist',Helvetica] font-semibold text-secondary-yellow text-[12.1px] tracking-[0] leading-[normal]">
                      t
                    </div>
                  </div>

                  <div className="absolute top-[70px] left-1 rotate-[140.17deg] [font-family:'Urbanist',Helvetica] font-semibold text-secondary-yellow text-[12.1px] tracking-[0] leading-[normal]">
                    y
                  </div>
                </div>

                <div className="absolute top-[86px] left-1 rotate-[150.30deg] [font-family:'Urbanist',Helvetica] font-semibold text-secondary-yellow text-[12.1px] tracking-[0] leading-[normal]">
                  {""}
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="inline-flex items-center gap-[144.63px] absolute top-[3941px] left-[267px]">
          <button className="all-[unset] box-border inline-flex items-center gap-[8.62px] px-[25.85px] py-[19.38px] relative flex-[0_0_auto] bg-secondary-yellow rounded-[33.39px] border-[2.15px] border-solid border-secondary-yellow">
            <div className="relative w-fit mt-[-2.15px] [font-family:'Urbanist',Helvetica] font-medium text-[#052e49] text-[19.4px] tracking-[0] leading-[29.1px] whitespace-nowrap">
              Interior Designs
            </div>
          </button>

          <button className="all-[unset] box-border inline-flex items-center gap-[8.62px] px-[25.85px] py-[19.38px] relative flex-[0_0_auto] bg-secondary-yellow rounded-[33.39px] border-[2.15px] border-solid border-secondary-yellow">
            <div className="relative w-fit mt-[-2.15px] [font-family:'Urbanist',Helvetica] font-medium text-[#052e49] text-[19.4px] tracking-[0] leading-[29.1px] whitespace-nowrap">
              Architectural Designs
            </div>
          </button>

          <button className="all-[unset] box-border inline-flex items-center gap-[8.62px] px-[25.85px] py-[19.38px] relative flex-[0_0_auto] bg-secondary-yellow rounded-[33.39px] border-[2.15px] border-solid border-secondary-yellow">
            <div className="relative w-fit mt-[-2.15px] [font-family:'Urbanist',Helvetica] font-medium text-[#052e49] text-[19.4px] tracking-[0] leading-[29.1px] whitespace-nowrap">
              Landscape Design
            </div>
          </button>
        </div>

        <div className="absolute w-[1396px] h-[312px] top-[4051px] left-0 bg-[#052e49]">
          <div className="absolute w-[322px] h-8 top-[123px] left-[149px] bg-secondary-yellow rounded-[18.58px]">
            <div className="absolute w-[305px] h-6 top-1 left-[9px]">
              <img
                className="absolute w-[19px] h-[19px] top-0.5 left-0"
                alt="Icon"
                src="./src/screens/Resource/img/icon.svg"
              />

              <div className="absolute w-[243px] top-0.5 left-[27px] [font-family:'Urbanist',Helvetica] font-medium text-primary-blue text-[14.5px] tracking-[-0.09px] leading-[19.4px]">
                Enter Your Email
              </div>

              <img
                className="absolute w-6 h-6 top-0 left-[279px]"
                alt="Icon"
                src="./src/screens/Resource/img/icon-1.svg"
              />
            </div>

            <img
              className="absolute w-px h-8 top-0 left-[283px]"
              alt="Line"
              src="./src/screens/Resource/img/line-5.svg"
            />
          </div>

          <div className="absolute w-[177px] h-[209px] top-[26px] left-[788px]">
            <div className="inline-flex flex-col items-start gap-[24.24px] absolute top-0 left-0">
              <div className="relative w-[53.7px] mt-[-0.81px] [font-family:'Urbanist',Helvetica] font-medium text-grey-60 text-[16.2px] tracking-[-0.10px] leading-[19.4px]">
                Home
              </div>

              <div className="flex flex-col w-[103.7px] items-start gap-[16.16px] relative flex-[0_0_auto]">
                <div className="relative w-fit mt-[-0.81px] [font-family:'Urbanist',Helvetica] font-medium text-[#ffffff] text-[14.5px] tracking-[-0.09px] leading-[19.4px] whitespace-nowrap">
                  Hero Section
                </div>

                <div className="relative w-fit [font-family:'Urbanist',Helvetica] font-medium text-[#ffffff] text-[14.5px] tracking-[-0.09px] leading-[19.4px] whitespace-nowrap">
                  Features
                </div>

                <div className="relative w-fit [font-family:'Urbanist',Helvetica] font-medium text-[#ffffff] text-[14.5px] tracking-[-0.09px] leading-[19.4px] whitespace-nowrap">
                  Properties
                </div>

                <div className="relative w-fit [font-family:'Urbanist',Helvetica] font-medium text-[#ffffff] text-[14.5px] tracking-[-0.09px] leading-[19.4px] whitespace-nowrap">
                  Testimonials
                </div>

                <div className="relative w-fit [font-family:'Urbanist',Helvetica] font-medium text-[#ffffff] text-[14.5px] tracking-[-0.09px] leading-[19.4px] whitespace-nowrap">
                  FAQ’s
                </div>
              </div>
            </div>

            <div className="w-[91px] top-0 left-[86px] flex flex-col items-start gap-[24.24px] absolute">
              <div className="relative w-fit mt-[-0.81px] [font-family:'Urbanist',Helvetica] font-medium text-grey-60 text-[16.2px] tracking-[-0.10px] leading-[19.4px] whitespace-nowrap">
                Properties
              </div>

              <div className="inline-flex flex-col items-start gap-[16.16px] relative flex-[0_0_auto]">
                <div className="relative w-fit mt-[-0.81px] [font-family:'Urbanist',Helvetica] font-medium text-[#ffffff] text-[14.5px] tracking-[-0.09px] leading-[19.4px] whitespace-nowrap">
                  Portfolio
                </div>

                <div className="relative w-fit [font-family:'Urbanist',Helvetica] font-medium text-[#ffffff] text-[14.5px] tracking-[-0.09px] leading-[19.4px] whitespace-nowrap">
                  Categories
                </div>
              </div>
            </div>
          </div>

          <div className="w-[105px] top-[21px] left-[674px] flex flex-col items-start gap-[24.24px] absolute">
            <div className="relative w-fit mt-[-0.81px] [font-family:'Urbanist',Helvetica] font-medium text-grey-60 text-[16.2px] tracking-[-0.10px] leading-[19.4px] whitespace-nowrap">
              About Us
            </div>

            <div className="inline-flex flex-col items-start gap-[16.16px] relative flex-[0_0_auto]">
              <div className="relative w-fit mt-[-0.81px] [font-family:'Urbanist',Helvetica] font-medium text-[#ffffff] text-[14.5px] tracking-[-0.09px] leading-[19.4px] whitespace-nowrap">
                Our Story
              </div>

              <div className="relative w-fit [font-family:'Urbanist',Helvetica] font-medium text-[#ffffff] text-[14.5px] tracking-[-0.09px] leading-[19.4px] whitespace-nowrap">
                Our Works
              </div>

              <div className="relative w-fit [font-family:'Urbanist',Helvetica] font-medium text-[#ffffff] text-[14.5px] tracking-[-0.09px] leading-[19.4px] whitespace-nowrap">
                How It Works
              </div>

              <div className="relative w-fit [font-family:'Urbanist',Helvetica] font-medium text-[#ffffff] text-[14.5px] tracking-[-0.09px] leading-[19.4px] whitespace-nowrap">
                Our Team
              </div>

              <div className="relative w-fit [font-family:'Urbanist',Helvetica] font-medium text-[#ffffff] text-[14.5px] tracking-[-0.09px] leading-[19.4px] whitespace-nowrap">
                Our Clients
              </div>
            </div>
          </div>

          <div className="w-[183px] top-[26px] left-[969px] flex flex-col items-start gap-[24.24px] absolute">
            <div className="relative w-fit mt-[-0.81px] [font-family:'Urbanist',Helvetica] font-medium text-grey-60 text-[16.2px] tracking-[-0.10px] leading-[19.4px] whitespace-nowrap">
              Services
            </div>

            <div className="inline-flex flex-col items-start gap-[16.16px] relative flex-[0_0_auto]">
              <div className="relative w-fit mt-[-0.81px] [font-family:'Urbanist',Helvetica] font-medium text-[#ffffff] text-[14.5px] tracking-[-0.09px] leading-[19.4px] whitespace-nowrap">
                Valuation Mastery
              </div>

              <div className="relative w-fit [font-family:'Urbanist',Helvetica] font-medium text-[#ffffff] text-[14.5px] tracking-[-0.09px] leading-[19.4px] whitespace-nowrap">
                Strategic Marketing
              </div>

              <div className="relative w-fit [font-family:'Urbanist',Helvetica] font-medium text-[#ffffff] text-[14.5px] tracking-[-0.09px] leading-[19.4px] whitespace-nowrap">
                Negotiation Wizardry
              </div>

              <div className="relative w-fit [font-family:'Urbanist',Helvetica] font-medium text-[#ffffff] text-[14.5px] tracking-[-0.09px] leading-[19.4px] whitespace-nowrap">
                Closing Success
              </div>

              <div className="relative w-fit [font-family:'Urbanist',Helvetica] font-medium text-[#ffffff] text-[14.5px] tracking-[-0.09px] leading-[19.4px] whitespace-nowrap">
                Property Management
              </div>
            </div>
          </div>

          <div className="w-[110px] top-[26px] left-[1156px] flex flex-col items-start gap-[24.24px] absolute">
            <div className="relative w-fit mt-[-0.81px] [font-family:'Urbanist',Helvetica] font-medium text-grey-60 text-[16.2px] tracking-[-0.10px] leading-[19.4px] whitespace-nowrap">
              Contact Us
            </div>

            <div className="inline-flex flex-col items-start gap-[16.16px] relative flex-[0_0_auto]">
              <div className="relative w-fit mt-[-0.81px] [font-family:'Urbanist',Helvetica] font-medium text-[#ffffff] text-[14.5px] tracking-[-0.09px] leading-[19.4px] whitespace-nowrap">
                Contact Form
              </div>

              <div className="relative w-fit [font-family:'Urbanist',Helvetica] font-medium text-[#ffffff] text-[14.5px] tracking-[-0.09px] leading-[19.4px] whitespace-nowrap">
                Our Offices
              </div>
            </div>
          </div>

          <img
            className="absolute w-[97px] h-8 top-[47px] left-[146px]"
            alt="Mask group"
            src="./src/screens/Resource/img/mask-group-7@2x.png"
          />

          <img
            className="absolute w-[121px] h-8 top-[47px] left-[266px]"
            alt="Mask group"
            src="./src/screens/Resource/img/mask-group-8@2x.png"
          />
        </div>

        <div className="flex w-[1398px] items-center justify-between px-[130.9px] py-[12.93px] absolute top-[4363px] left-0 bg-primary-blue border-[0.81px] border-solid border-secondary-yellow">
          <div className="inline-flex items-start gap-[30.7px] px-0 py-[8.08px] relative flex-[0_0_auto]">
            <p className="relative w-fit mt-[-0.81px] [font-family:'Urbanist',Helvetica] font-medium text-absolutewhite text-[14.5px] tracking-[-0.09px] leading-[19.4px] whitespace-nowrap">
              @2024 CRE8IVY. All Rights Reserved.
            </p>

            <div className="relative w-fit mt-[-0.81px] [font-family:'Urbanist',Helvetica] font-medium text-absolutewhite text-[14.5px] tracking-[-0.09px] leading-[19.4px] whitespace-nowrap">
              Terms &amp; Conditions
            </div>
          </div>

          <img
            className="relative flex-[0_0_auto]"
            alt="Social icon"
            src="./src/screens/Resource/img/social-icon.svg"
          />
        </div>

        <div className="absolute w-[681px] top-[3880px] left-[225px] font-semibold text-primary-blue text-[28.6px] leading-[42.9px] [font-family:'Urbanist',Helvetica] tracking-[0]">
          We cater to
        </div>
      </div>
    </div>
  );
};
